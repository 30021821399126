body {
  background-color: #202830;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #394755;
  display: flex;
  justify-content: center;
  align-items: center;
}

#search-bar {
  color: white;
}

.github-logo {
  transition: 0.5s;
  transition-property: opacity;
  opacity: 20%;
}

.github-logo:hover {
  transition: 0.5s;
  opacity: 100%;
}
